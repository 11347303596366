<template>
    <div
        style="margin-right: 15px;"
    >
        <div
            v-if="isLoggedIn"
        >
            <a
                @click="showUserDetails=!showUserDetails" 
            >
                <v-icon 
                    icon="fa:fas fa-user" 
                />
                [ {{ user.name }} ]
            </a>
            <a 
                @click="logout"
            >
                Logout
            </a>
        </div>
        <div
            v-else
        >
            <router-link :to="{ name: 'login' }">Login</router-link>
        </div>
        <v-dialog
            v-model="showUserDetails"
            max-width="60%"
            height="100%"
        >
            <v-card
                v-if="isLoggedIn"
            >
                <v-card-title>
                    Active User
                </v-card-title>
                <v-card-text>
                    Name: {{ user.name }}
                    <br />
                    Email: {{ user.email }}
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import sessionStore from '../stores/SessionStore.ts';

    export default {
        name: "SessionManager",
        setup (props, context) {
            const { isLoggedIn, user, sessionStateChange } = sessionStore();

            if (window.__INITIAL_STATE__.user) {
                sessionStateChange(window.__INITIAL_STATE__.user);
            }

            return {
                isLoggedIn,
                user
            }
        },
        data() {
            return {
                showUserDetails: false
            }
        },
        methods: {
            logout(e) {
                e.preventDefault()
                this.$axios.get('/sanctum/csrf-cookie').then(response => {
                    this.$axios.post('/api/logout')
                    .then(response => {
                        if(response.data.success) {
                            window.location.href = "/"
                        } else {
                            console.log(response);
                            alert(response.data.error);
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        alert(error.response.data.error);
                    });
                })
            }
        },
    }
</script>

<style>

</style>
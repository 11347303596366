<template>
    <div
        xs12
        class="d-flex flex-column mb-6 Terminal"
        @click="eventHandler_holdFocus"
    >
        <div>
            <div
                v-for="(entry, index) in consoleLog"
                :key="index"
                class="history_entry"
                @click="historyClicked(index)"
            >
                <div
                    v-if="entry.type === historyTypes.UI"
                    class="history_type"
                    :class="[entry.type]"
                    style="margin-right:5px;"
                >
                    EndlessTheory.com&nbsp;>
                </div>
                <div
                    v-else-if="entry.type === historyTypes.ER"
                    class="history_type"
                    style="vertical-align:top;"
                    :class="[entry.type]"
                >
                    +
                </div>
                <div
                    class="user_entry"
                    v-html="entry.msg"
                />
            </div>
        </div>
        <div id="UserInput">
            <div class="history_type">
                EndlessTheory.com&nbsp;>
            </div>
            <input
                ref="userInput"
                v-model="userInput"
                class="input user_entry"
                @keydown="eventHandler_keydown"
            >
        </div>
        <div ref="scrollToMe"></div>
    </div>
</template>

<script>
import fauxConsoleStore from '../../stores/FauxConsoleStore.ts';

import sessionStore from '../../stores/SessionStore.ts';
const { isLoggedIn } = sessionStore();

// Private Globals
export default {
    name: 'FauxConsole',
    setup (props, context) {
        const { historyTypes, consoleLog, inputHistoryLog, pushToConsoleLog, pushToInputHistoryLog, clearConsoleLog } = fauxConsoleStore();

        return {
            historyTypes,
            consoleLog,
            inputHistoryLog,
            pushToConsoleLog,
            pushToInputHistoryLog,
            clearConsoleLog,
        }
    },

    data() {
        return {
            userInput: '',

            sessionIndex: this.inputHistoryLog.length,
        };
    },

    mounted() {
        this.eventHandler_holdFocus();
    },

    methods: {
        scrollToElement() {
            const el = this.$refs.scrollToMe;
            if (el) {
                el.scrollIntoView({ behavior: "smooth" });
            }
        },
        logToConsole(iTxt, iHistoryType, doLogToHistory = true) {
            const newHistoryNode = {
                msg: iTxt,
                type: iHistoryType,
            };

            this.pushToConsoleLog(newHistoryNode);

            // History
            if (doLogToHistory) {
                this.pushToInputHistoryLog(newHistoryNode);
                this.sessionIndex = this.inputHistoryLog.length;
            }
        },

        execCmd() {
            let processOutputLatest = null;

            const inputTxtLower = this.userInput.toLowerCase();

            if (inputTxtLower === '') {
                return; // Do Nothing on blank input
            }

            this.logToConsole(this.userInput, this.historyTypes.UI, true);

            // ----------
            // CMD Interpreter
            // --
            // TODO: Use Cmd Hash?
            // QUESTION: Does a switch function as a hash or an object?
            switch (inputTxtLower) {
                case 'help':
                    processOutputLatest = `
                        <div class="ls_header">Console Commands:</div>
                        <div class="ls_firstColumn">> ls | dir</div>List Directory Contents<br>
                        <div class="ls_firstColumn">> cls | clear</div>Clear Screen<br>
                        <div class="ls_firstColumn">> help</div>Help Menu (this menu)<br>
                        <div class="ls_firstColumn">[UpArrow/DownArrow]</div>Cycle cmd history<br>
                        <br>

                        <div class="ls_header">Page Navigation:</div>
                        <div class="ls_firstColumn">[Alt]+[RightArrow]</div>Navigate Forward<br>
                        <div class="ls_firstColumn">[Alt]+[LeftArrow]</div>Navigate Back<br>
                        <div class="ls_firstColumn">[F5]</div>Reload Page<br>
                        <br>
                    `;
                break;
                case 'ls':
                case 'dir':
                    processOutputLatest = `
                        <div class="ls_firstColumn">> help</div>Help Menu<br>
                        <div class="ls_header">App Navigation:</div>
                        <div class="ls_firstColumn">> gmgm</div>Grid Maker for Grid Move [v1.0.0]<br>
                        <div class="ls_firstColumn">> [bg | BoldlyGo]</div>Boldly Go - A Javascript Adventure Game [Experimental]<br>
                        <div class="ls_firstColumn">> [tb | TimeBox]</div>TimeBox - Gameify your life [Experimental]<br>
                        <div class="ls_firstColumn">> [ft | FactTree]</div>Fact Tree (Concept)<br>
                        <div class="ls_firstColumn">> [kb | kanban]</div>Kanban<br>
                        <div class="ls_firstColumn">> [refs | links]</div>References / Web Links<br>
                        <!-- <div class="ls_firstColumn">> prvt</div>[Private] Privateering Game Manager [Pre Alpha]<br>
                        <div class="ls_firstColumn">> [kb | kanban]</div>[Private] A Kanban board [v3.0.2]<br>
                        <br>

                        <div class="ls_header">EndlessTheory.com Settings:</div> -->
                        <br>

                        <div class="ls_header">About:</div>
                        > endlesstheory<br>
                        > privateering<br>
                        <br>

                        <div class="ls_header">Special Thanks:</div>
                        > andris<br>
                        > sticker | skate | endlesstheory.us<br>
                        <br>
                    `;
                    break;
                case 'cls':
                case 'clear':
                    this.clearConsoleLog();
                    break;
                case 'endlesstheory':
                    processOutputLatest = 'Without questions there can be no answers --Endless<br><br>';
                    break;
                case 'privateering':
                    processOutputLatest = 'Explore || Die; Produce || Die; Protect || Die; Expand || Die; Destroy || Die; Dominate && Win<br><br>';
                    break;

                // Special Thanks
                case 'andris':
                    processOutputLatest = "<a target='_blank' href='https://andris.co/'>https://andris.co/</a><br>To Andris: I've wanted this for a LONG time - Thank You!<br><br>";
                    break;
                case 'sticker':
                case 'skate':
                case 'endlesstheory.us':
                    processOutputLatest = "I think you meant '.us' <br> <a target='_blank' href='https://www.endlesstheory.us/'>https://www.endlesstheory.us/</a><br><br>";
                    break;


                // Navigation Routes
                case 'login':
                    if(isLoggedIn.value) {
                        processOutputLatest = "Warning: Active Session Detected! Please logout before logging into new account.";
                    } else {
                        this.$router.push({name: 'login'});
                    }
                    break;
                case 'register':
                    if(isLoggedIn.value) {
                        processOutputLatest = "Warning: Active Session Detected! Please logout before registering a new account.";
                    } else {
                        this.$router.push({name: 'register'});
                    }
                    break;
                case 'gmgm':
                    this.$router.push({name: 'gridMoveGridMaker'});
                    break;
                case 'bg':
                case 'boldlygo':
                    this.$router.push({name: 'boldlyGo'});
                    break;
                case 'tb':
                case 'timebox':
                    this.$router.push({name: 'timeBox'});
                    break;
                // case 'prvt':
                //     this.$router.push({name: 'prvt_games'});
                //     break;
                case 'kb':
                case 'kanban':
                    this.$router.push({name: 'kanban'});
                    break;
                case 'ft':
                case 'facttree':
                    this.$router.push({name: 'factTree'});
                    break;
                case 'refs':
                case 'references':
                case 'links':
                    this.$router.push({name: 'references'});
                    break;

                default:
                    processOutputLatest = `'${this.userInput}' is not a recognized command<br>`;
                    break;
            }

            if (processOutputLatest) {
                this.logToConsole(processOutputLatest, this.historyTypes.ER, false);
            }
        },

        // Event Handlers
        historyClicked(iIndex) {
            //this.userInput = this.$store.state.fauxConsole.consoleLog[iIndex].msg;
        },

        eventHandler_holdFocus() {
            this.$nextTick(() => this.$refs.userInput.focus());
        },
        eventHandler_keydown(e) {
            if (e.which === 13) { // KEY:Enter
                this.execCmd();

                this.userInput = ''; // Clear Input

                this.$nextTick(() => this.scrollToElement()); //scroll to bottom

                return false;
            }

            const inputHistoryLogLength = this.inputHistoryLog.length;

            if (e.which === 38) { // KEY:Up Arrow
                if (inputHistoryLogLength === 0) {
                    return false;
                }
                this.sessionIndex -= 1;

                if (this.sessionIndex < 0) {
                    this.sessionIndex = 0;
                    return false;
                }

                this.userInput = this.inputHistoryLog[this.sessionIndex].msg;

                return false;
            }
            if (e.which === 40) { // KEY:Down Arrow
                if (inputHistoryLogLength === 0) {
                    return false;
                }

                this.sessionIndex += 1;

                if (this.sessionIndex >= inputHistoryLogLength) {
                    this.sessionIndex = inputHistoryLogLength;
                    this.userInput = "";
                } else {
                    this.userInput = this.inputHistoryLog[this.sessionIndex].msg;
                }

                return false;
            }

            return true;
        },
    },
};
</script>

<style>
    /* TODO: Refactor the code so these styles can be used dynamically allowing it to go back into the scoped style below */
    .ls_header
    {
        font-size: 1.3em;
    }
    .ls_firstColumn
    {
        display:inline-block;
        width:200px;
    }
</style>

<style scoped>
    .Terminal
    {
        max-height: calc(100vh - 55px);
        background-color: black;
        color: white;
        font-family: 'Roboto Mono', monospace;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
    .Terminal input
    {
        color: white !important;
    }

    /*History*/
    .history_type
    {
        display: inline-block;
        text-align: center;
        margin-top: 2px;
        margin-right: 5px;
        font-size: 0.9em;
    }
    .execution_return
    {
        color: red;
        width: 15px;
    }
    #UserInput
    {
        display: flex;
        padding-bottom: 50px;
    }
    .user_entry
    {
        display: inline-block;
        flex-grow: 100;
    }

    /*User Input*/
    /* [contenteditable="true"]
    {

    } */
    .input:focus /* Hide native boarder when editing */
    {
        outline: 0px solid transparent;
    }

    .history_entry:hover
    {
        background-color: #111;
    }
</style>

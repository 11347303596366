const routes_ref = [
  {
    path: '/references',
    name: 'references',
    // lazy-loaded when the route is visited.
    component: () => import('../pages/References.vue'),
  }
];

export default routes_ref;

import { computed, Ref, ref } from "vue";

const isLoggedIn = ref(false);
const user = ref({});

function setUser(iUser) {
    isLoggedIn.value = (iUser != false);
    if(isLoggedIn.value)
    {
        user.value = iUser;
    }
}

function sessionStateChange(iUser) {
    setUser(iUser);
}

export default function () {
    return {
        isLoggedIn,
        user,
        sessionStateChange
    };
}
<template>
    <v-alert 
        v-if="isError"
        type="error"
    >
        {{error}}
    </v-alert>
</template>

<script>
    import errorStore from '../stores/ErrorStore.ts';

    export default {
        name: "MainError",
        setup (props, context) {
            const { isError, error, clearError } = errorStore();

            return {
                isError,
                error,
                clearError
            }
        },
        methods: {
            close(e) {
                // esc key to close error window
            }
        },
    }
</script>

<style>

</style>
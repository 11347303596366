const routes_gmgm = [
  {
    path: '/gmgm',
    name: 'gridMoveGridMaker',
    // lazy-loaded when the route is visited.
    component: () => import('../pages/GridMoveGridMaker.vue'),
  }
];

export default routes_gmgm;

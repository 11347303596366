const lazyLoad = () => import('../pages/Kanban.vue');
const routes_ref = [
  {
    path: '/kanban',
    name: 'kanban',
    // lazy-loaded when the route is visited.
    component: lazyLoad,

    //-----------------------
    //-- Default "View"
    // HACK!
    // FIXME:
    // TODO: Should not use redirect to control flow? Should we?
    //       Does not change the view correctly when from kanban page 
    //       with View=Full selected and then this link fires the 
    //       componenet does not reload as desired
    redirect: {
      name: 'kanbanView',
      params: {'view': 'Full'},
    },
    //=======================
  },
  {
    path: '/kanban/:view',
    name: 'kanbanView',
    component: lazyLoad,
    props: true,
  },
  {
    path: '/kanban/:view/:projects',
    name: 'kanbanProjects',
    component: lazyLoad,
    props: true,
  },
  {
    path: '/kanban/:view/:projects/:tags',
    name: 'kanbanTags',
    component: lazyLoad,
    props: true,
  }

];

export default routes_ref;

import { computed, Ref, ref } from "vue";

const isError = ref(false);
const error = ref("No Error");

function escPressed(e) {
    if (e.keyCode === 27) {
        clearError();
    }
}

async function clearError() {
    isError.value = false;
    error.value = "No Error";

    document.body.removeEventListener('keyup', escPressed);
}

async function setError(iError) {
    isError.value = (iError != "");
    error.value = iError;

    document.body.addEventListener('keyup', escPressed);
}

export default function () {
    return {
        isError,
        error,
        setError,
        clearError
    };
}
<template>
    <v-card>
        <v-card-title>
        Access Verification
        </v-card-title>
        <v-card-text>
            <v-form
                ref="formAuth"
                v-model="valid"
            >
                <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    :error-messages="error.name"
                    label="name"
                    required
                    autofocus
                />
                <v-text-field
                    v-model="password"
                    :type="'password'"
                    :rules="passwordRules"
                    :error-messages="error.password"
                    label="Password"
                    required
                />
                <div
                    class="d-flex justify-end"
                >
                    <v-btn
                        elevation="3"
                        size="x-large"
                        type="submit"
                        @click="login"
                    >
                        Submit
                    </v-btn>
                </div>
            </v-form>
            <div
                :v-html="error.message"
                class="error"
            />
            <br />
            <div
                class="fluid text-end"
            >
                Don't have an account? Please 
                <router-link :to="{ name: 'register'}" >Register</router-link>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import sessionStore from '../stores/SessionStore.ts';
    import errorStore from '../stores/ErrorStore.ts';

    const { isLoggedIn } = sessionStore();

    const clearErrors = () => { 
        return JSON.parse(
            JSON.stringify({
                name: '',
                password: '',
                message: '',
            })
        );
    };

    export default {
        data() {
            return {
                name: "",
                email: "",
                password: "",
                error: clearErrors(),

                valid: false,

                nameRules: [
                    (v) => !!v || 'Name is required',
                ],
                passwordRules: [
                    (v) => !!v || 'Password is required',
                ],
            }
        },
        methods: {
            forceValidation(e) {
                this.error = clearErrors();
                this.$refs.formAuth.validate();
            },
            login(e) {
                e.preventDefault();
                var that = this;
                
                errorStore().clearError();
                this.forceValidation();

                if(!this.valid) {
                    this.error.message = 'Please complete the form before submitting';
                    return;
                }
                
                if(this.password.length > 0) {
                    this.$axios.get('/sanctum/csrf-cookie').then(response => {
                        this.$axios.post('api/login', {
                            name: this.name,
                            email: this.email,
                            password: this.password
                        })
                        .then(response => {
                            if (response.data.success) {
                                confirm(`Login Successful - Welcome ${this.name}`);
                                this.$router.go('/login');
                            } else {
                                this.error = response.data.message
                            }
                        })
                        .catch(function (error) {
                            console.error(error);
                            that.error = error.response.data.error;
                            errorStore().setError(`${error} - ${JSON.stringify(error.response.data.error)}`);
                        });
                    })
                }
            },
        },

        beforeRouteEnter(to, from, next) {
            if(isLoggedIn.value) {
                return next('/');
            }
            next();
        }
    }
</script>
<template>
    <v-card>
        <v-card-title>
            Register New Asset
        </v-card-title>
        <v-card-text>
            <v-form
                ref="formReg"
                v-model="valid"
            >
                <v-text-field
                    v-model="name"
                    label="name"
                    :counter="10"
                    :rules="nameRules"
                    :error-messages="error.name"
                    :error-count="2"
                    required
                    autofocus
                    @change="error.name = ''"
                />
                <v-text-field
                    v-model="email"
                    label="email"
                    :rules="emailRules"
                    :error-messages="error.email"
                    :error-count="2"
                    required
                    @change="error.email = ''"
                />
                <v-text-field
                    v-model="password"
                    :type="'password'"
                    label="Password"
                    :rules="passwordRules"
                    :error-messages="error.password"
                    required
                />
                <v-text-field
                    v-model="passwordConfirm"
                    :type="'password'"
                    label="Password Confirm"
                    :rules="passwordConfirmRules"
                    required
                    validate-on-blur
                />
                <v-text-field
                    v-model="passphrase"
                    :type="'passphrase'"
                    label="Passphrase"
                    :rules="passphraseRules"
                    :error-messages="error.passphrase"
                    required
                />
                <div
                    class="d-flex justify-end"
                >
                    <v-btn
                        size="large"
                        @click="register"
                        type="submit"
                    >
                        Bring New Asset Online
                    </v-btn>
                </div>
                <div
                    v-html="error.message"
                    class="error"
                />
                <v-text-field
                    v-if="isLoading"
                    loading
                    disabled
                ></v-text-field>
                <br 
                    v-else
                />
                <div
                    class="fluid text-end"
                >
                    Already have an account? Please
                    <router-link :to="{ name: 'login'}" >login</router-link>
                </div>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import sessionStore from '../stores/SessionStore.ts';
    import errorStore from '../stores/ErrorStore.ts';

    const { isLoggedIn } = sessionStore();

    const clearErrors = () => { 
        return JSON.parse(
            JSON.stringify({
                name: '',
                email: '',
                password: '',
                passphrase: '',
                message: '',
            })
        );
    };

    export default {
        data() {
            return {
                name: "",
                email: "",
                password: "",
                passwordConfirm: '',
                passphrase: "",
                error: clearErrors(),
                isLoading: false,
                valid: false,

                nameRules: [
                    (v) => !!v || 'Name is required',
                    (v) => v.length <= 10 || 'Name must be less than 10 characters',
                ],
                emailRules: [
                    (v) => !!v || 'E-mail is required',
                    (v) => /.+@.+/.test(v) || 'E-mail must be valid',
                ],
                passwordRules: [
                    (v) => !!v || 'Password is required',
                    (v) => v.length >= 10 || 'Password must be at least 10 characters',
                ],
                passwordConfirmRules: [
                    (v) => !!v || 'Password Confirmation is required',
                    (v) => v === this.password || 'Passwords do not match',
                ],
                passphraseRules: [
                    (v) => !!v || 'Passphrase is required',
                ],
            }
        },

        methods: {
            forceValidation(e) {
                this.error = clearErrors();
                this.$refs.formReg.validate();
            },
            register(e) {
                e.preventDefault();
                var that = this;

                errorStore().clearError();
                this.forceValidation();

                if(!this.valid) {
                    this.error.message = 'Please complete the form before submitting';
                    return;
                }

                this.isLoading = true;
                
                this.$axios.get('/sanctum/csrf-cookie').then(response => {
                    this.$axios.post('api/register', {
                        name: this.name,
                        email: this.email,
                        password: this.password,
                        passphrase: this.passphrase
                    })
                    .then(response => {
                        if (response.data.success) {
                                window.location.href = "/login"
                        } else {
                            this.error = response.data.message
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        that.error = error.response.data.error;
                        errorStore().setError(`${error} - ${JSON.stringify(error.response.data.error)}`);
                    })
                    .finally(function() {
                        that.isLoading = false;
                    });
                });
            }
        },

        beforeRouteEnter(to, from, next) {
            if (isLoggedIn.value) {
                return next('/');
            }
            next();
        }
    }
</script>

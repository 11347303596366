const routes_tb = [
  {
    path: '/timebox',
    name: 'timeBox',
    // lazy-loaded when the route is visited.
    component: () => import('../pages/TimeBoxManager.vue'),
  }
];

export default routes_tb;

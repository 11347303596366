import './bootstrap';

import { createApp } from 'vue';
//import 'material-design-icons-iconfont/dist/material-design-icons.css';

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import axios from 'axios';
import App from './App.vue';
import router from './router';

loadFonts();

const app = createApp(App);

app.config.globalProperties.$axios = axios;
app.use(vuetify);
app.use(router);
app.mount('#app');
import { computed, Ref, ref } from "vue";

const historyTypes = {
    UI: 'user_input',
    ER: 'execution_return',
};

const consoleLog = ref([
    {
        msg: "Welcome to Endlesstheory.com - Explore, Play, Create",
        type: historyTypes.ER
    },
]);

const inputHistoryLog = ref([]);

async function pushToConsoleLog(entry) {
    consoleLog.value.push(entry);
};

async function pushToInputHistoryLog(entry) {
    inputHistoryLog.value.push(entry);
};

async function clearConsoleLog() {
    consoleLog.value = [];
};

export default function () {
    return {
        historyTypes,

        consoleLog,
        inputHistoryLog,
        pushToConsoleLog,
        pushToInputHistoryLog,
        clearConsoleLog
    };
}
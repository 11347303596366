<template>
    <v-app>
        <v-app-bar
            app
            color="primary"
            dark
        >
            <v-app-bar-title>
                <h3><router-link :to="{name: 'console'}">EndlessTheory</router-link></h3>
            </v-app-bar-title>
            <template v-if="isLoggedIn">
                <router-link :to="{name: 'kanban'}">Kanban</router-link>
                &emsp;
                <router-link :to="{name: 'timeBox'}">TimeBox</router-link>
                &emsp;
                <router-link :to="{name: 'factTree'}">FactTree</router-link>
                &emsp;
                <router-link :to="{name: 'references'}">References</router-link>
            </template>
            &emsp;
            <router-link :to="{name: 'gridMoveGridMaker'}">GridMoveGridMaker</router-link>
            &emsp;
            <router-link :to="{name: 'boldlyGo'}">BoldlyGo</router-link>
            <v-spacer />
            <session-manager />
        </v-app-bar>
        <v-main>
            <div
                style="height: calc(100vh - 88px);"
            >
                <main-error />
                <router-view></router-view>
            </div>
        </v-main>
        <div style="position: absolute; bottom: 0px; left: 25px; z-index: 1010;">
            <a href="https://vuejs.org/" target="_blank">
                Vue v{{Version}}
            </a>
            &nbsp;
            <a href="https://next.vuetifyjs.com/en/" target="_blank">
                Vuetify v3.Beta
            </a>
        </div>
    </v-app>
</template>
<script>
    import sessionStore from './stores/SessionStore.ts';
    //import errorStore from './stores/ErrorStore.ts';

    import MainError from './components/MainError.vue';
    import SessionManager from './components/SessionManager.vue';
    import { version } from "vue";

    export default {
        name: "App",
        components: {
            MainError,
            SessionManager,
        },
        setup (props, context) {
            const { isLoggedIn } = sessionStore();

            //errorStore().setError("App Error Test #1 (esc to dismiss)");

            if (window.__INITIAL_STATE__.node_env) {
                console.log("Environment: " + window.__INITIAL_STATE__.node_env);
            }

            return {
                Version: version,
                isLoggedIn
            }
        },
    }
</script>
<style lang="scss">
    html
    {
        font-family: 'Roboto', sans-serif;
        overflow: hidden;
    }

    .v-app-bar-title
    {
        max-width: 200px;
    }

    .v-app-bar a
    {
        color: white !important;
        text-decoration: none;
        cursor: pointer;
    }
</style>

import {createWebHistory, createRouter} from "vue-router";

import Register from '../pages/Register';
import Login from '../pages/Login';
import Console from '../pages/Console';

// Routes
import routes_gmgm from './routes_gmgm.js';
import routes_bg from './routes_bg';
import routes_tb from './routes_tb';
import routes_ft from './routes_ft';
import routes_ref from './routes_ref';
import routes_kanban from './routes_kanban';

export const routes = [
    {
        name: 'console',
        path: '/',
        component: Console
    },
    {
        name: 'register',
        path: '/register',
        component: Register
    },
    {
        name: 'login',
        path: '/login',
        component: Login
    },
    ...routes_gmgm,
    ...routes_bg,
    ...routes_tb,
    ...routes_ft,
    ...routes_ref,
    ...routes_kanban
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

export default router;

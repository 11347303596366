<template>
    <faux-console />
</template>

<script>
import FauxConsole from '../components/fauxConsole/FauxConsole.vue';

export default {
    components: { FauxConsole },
    name: "Console"
}
</script>
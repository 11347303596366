const routes_ref = [
  {
    path: '/facttree',
    name: 'factTree',
    // lazy-loaded when the route is visited.
    component: () => import('../pages/FactTree.vue'),
  }
];

export default routes_ref;
